import React from 'react'
import { ThemeProvider } from 'styled-components'

import { themes } from 'styles/theme'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'
import RichText from 'components/RichText'
import JobPosting from 'components/careers/JobPosting'

const openings = [
  {
    title: 'Full Stack Developer',
    description: 'Full-time • Montreal, Canada (remote)',
    link: '/careers/full-stack-developer/',
  },
  {
    title: 'Senior Full Stack Developer',
    description: 'Full-time • Montreal, Canada (remote)',
    link: '/careers/senior-full-stack-developer/',
  },
  {
    title: 'Product Designer',
    description: 'Full-time • Montreal, Canada (remote)',
    link: '/careers/product-designer/',
  },
  {
    title: 'Project Manager',
    description: 'Full-time • Montreal, Canada (remote)',
    link: '/careers/project-manager/',
  },
]

const Openings = () => (
  <ThemeProvider theme={themes.dark}>
    <Section id="openings" hasBorder>
      <Wrapper size="narrow">
        <SectionBlock>
          <Heading size={2}>Openings</Heading>
          <RichText>
            <p>
              If you're hard-working, meticulous and passionate about your
              craft, we'd love to hear from you. Don't see the right opening?{' '}
              <a href="mailto:careers@volume7.io">Introduce yourself anyways</a>
              .
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          {openings.map((opening, i) => (
            <JobPosting
              key={i}
              title={opening.title}
              subtitle={opening.description}
              link={opening.link}
            />
          ))}
        </SectionBlock>
      </Wrapper>
    </Section>
  </ThemeProvider>
)

export default Openings
