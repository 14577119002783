import React from 'react'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Perk from 'components/careers/Perk'
import PerkGrid from 'components/PerkGrid'

import Bag from 'assets/icons/bag.svg'
import Health from 'assets/icons/heart.svg'
import House from 'assets/icons/house.svg'
import Clock from 'assets/icons/clock.svg'
import Computer from 'assets/icons/computer.svg'
import Money from 'assets/icons/money.svg'

const Benefits = () => (
  <Section id="benefits" hasBorder>
    <SectionBlock>
      <Wrapper size="narrow">
        <Heading size={2}>Perks & benefits</Heading>
        <RichText>
          <p>
            Building great digital products wouldn't be possible without our
            team, which is why we make its happiness our priority.
          </p>
        </RichText>
      </Wrapper>
    </SectionBlock>
    <SectionBlock>
      <Wrapper>
        <PerkGrid>
          <Perk
            icon={<Clock />}
            heading="Flexible schedule"
            description="Whether you're an early riser or a night owl, work while you're at your most productive."
          />
          <Perk
            icon={<Money />}
            heading="Profit sharing"
            description="We distribute a generous portion of company profits to all team members on a quarterly basis."
          />
          <Perk
            icon={<Bag />}
            heading="Team retreats"
            description="Company-wide retreats where you can experiment with new tech, tackle a side project, and bond with coworkers."
          />

          <Perk
            icon={<Health />}
            heading="Health benefits"
            description="Generous health and dental coverage to set your mind at ease."
          />
          <Perk
            icon={<Computer />}
            heading="Tech setup"
            description="We'll provide you with a top-of-the-line MacBook and monitor so you can do your best work."
          />
          <Perk
            icon={<House />}
            heading="Work from home"
            description="Enjoy the freedom and flexibility that comes with working from where you feel most productive."
          />
        </PerkGrid>
      </Wrapper>
    </SectionBlock>
  </Section>
)

export default Benefits
