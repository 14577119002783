import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { mix } from 'polished'

import { animationTimes, animationCurve, borderRadius } from 'styles/variables'
import { largerThan } from 'styles/media'
import { black, greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Arrow from 'assets/icons/chevron-right.svg'

import Heading from 'components/Heading'
import Icon from 'components/Icon'

const Container = styled(Link)`
  display: flex;
  align-items: center;
  transition: background-color ${animationTimes.base} ${animationCurve};
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${mix(0.1, black, greyDark)};
  transition: background-color ${animationTimes.base} ${animationCurve};
  padding: ${spacing.scale(spacing.medium, 1.25)} ${spacing.large};

  ${largerThan.medium`
    padding: ${spacing.xLarge};
  `};

  & + & {
    margin-top: ${spacing.medium};

    ${largerThan.large`
      margin-top: ${spacing.large};
    `};
  }

  &:hover {
    background-color: ${mix(0.15, black, greyDark)};
  }
`

const Main = styled.main`
  flex: 1;
`

const Name = styled(Heading)`
  color: ${({ theme }) => theme.heading};
  margin-bottom: ${spacing.scale(spacing.tiny, 1.5)};

  ${largerThan.small`
    margin-bottom: ${spacing.small};
  `};

  ${largerThan.medium`
    width: 380px;
  `};

  ${largerThan.large`
    width: 420px;
  `};
`

const Description = styled.span`
  display: block;
  color: ${({ theme }) => theme.body};
  font-size: 0.9em;
`

const IconWrap = styled.div`
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.heading};
`

const JobPosting = ({ title, subtitle, link }) => (
  <Container to={link}>
    <Main>
      <Name size={4}>{title}</Name>
      <Description>{subtitle}</Description>
    </Main>
    <IconWrap>
      <Icon icon={<Arrow />} />
    </IconWrap>
  </Container>
)

export default JobPosting
