import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import Ethos from 'components/careers/Ethos'
import Benefits from 'components/careers/Benefits'
import Openings from 'components/careers/Openings'

const Careers = () => (
  <>
    <MetaTags title="Careers | Custom software development in Montreal | Volume7" />
    <PageHeader
      heading={
        <>
          Work at <span>Volume7</span>
        </>
      }
      description="We're looking for hard-working and passionate self-starters to help us build outstanding digital products."
      figure={
        <StaticImage
          src="../assets/images/careers/office.jpg"
          layout="fullWidth"
          alt="Alt text"
          placeholder="blurred"
        />
      }
    />
    <Ethos />
    <Benefits />
    <Openings />
  </>
)

export default Careers
