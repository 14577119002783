import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import TestimonialSlider from 'components/TestimonialSlider'

const Ethos = () => (
  <Section flushTop>
    <SectionBlock>
      <Wrapper size="narrow">
        <Heading size={2}>Ethos</Heading>
        <RichText>
          <p>
            We pride ourselves in doing quality, meaningful work that delights
            our clients while positively impacting people's lives. Prioritizing
            challenging and fulfilling projects over our bottom line is
            something we're comfortable doing.
          </p>

          <p>
            All team members are encouraged to get involved. Whether it's for
            one of our client projects or for the betterment of our company and
            its processes, everyone's insight is valuable.
          </p>
          <p>
            <AnchorLink href="#openings">View our openings</AnchorLink>
          </p>
        </RichText>
      </Wrapper>
    </SectionBlock>
    <SectionBlock>
      <Wrapper>
        <TestimonialSlider
          testimonials={[
            {
              name: 'Robynn Lobo-Pires',
              role: 'Full Stack Developer',
              quote:
                'I am offered knowledgeable and invested mentorship, the freedom to take initiative and ownership of my projects, and the opportunity to take on different responsibilities.',
            },
            {
              name: 'Alexander Haniotis',
              role: 'Co-Founder & Design Director',
              quote:
                "Honing your skills all while having fun working on challenging projects—that's exactly why we started Volume7 (and choose to keep going!).",
            },
          ]}
        />
      </Wrapper>
    </SectionBlock>
  </Section>
)

export default Ethos
