import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { widowFix } from 'utils/string'

import { square } from 'styles/helpers'
import { borderRadius } from 'styles/variables'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Icon from 'components/Icon'

const Container = styled.div`
  position: static;

  ${(props) =>
    props.direction === 'horizontal' &&
    css`
      display: flex;
    `}
`

const IconWrap = styled.div`
  display: flex;
  margin-bottom: ${spacing.small};
  color: ${({ theme }) => theme.heading};

  ${largerThan.small`
    margin-bottom: ${spacing.scale(spacing.small, 1.5)};
  `};

  ${largerThan.medium`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => rgba(theme.heading, 0.075)};
    border-radius: ${borderRadius};
    margin-bottom: ${spacing.medium};
    ${square('48px')};
  `};

  ${(props) =>
    props.direction === 'horizontal' &&
    css`
      margin-right: ${spacing.scale(spacing.small, 1.5)};

      ${largerThan.small`
        margin-right: ${spacing.medium};
      `};

      ${largerThan.medium`
        margin-right: ${spacing.medium};
      `};

      ${largerThan.large`
        margin-right: ${spacing.scale(spacing.medium, 1.25)};
      `};

      ${largerThan.xLarge`
        margin-right: ${spacing.large};
      `};
    `}
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.small};
`

const Main = styled.main`
  flex: 1;
`

const Perk = ({ icon, heading, description, direction = 'vertical' }) => (
  <Container direction={direction}>
    <IconWrap direction={direction}>
      <Icon icon={icon} />
    </IconWrap>
    <Main>
      <Title size={5}>{heading}</Title>
      <RichText>
        <p>{widowFix(description)}</p>
      </RichText>
    </Main>
  </Container>
)

export default Perk
