import React from 'react'
import styled, { css } from 'styled-components'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.large};

  ${largerThan.small`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${spacing.xLarge};

    ${(props) =>
      props.twoUp &&
      css`
        grid-template-columns: repeat(2, 1fr);
      `}
  `};

  ${largerThan.large`
    grid-gap: ${spacing.xxLarge};
  `};

  ${largerThan.xLarge`
    grid-column-gap: ${spacing.xxxLarge};
    grid-row-gap: 3rem;
  `};
`

const PerkGrid = ({ twoUp, children }) => (
  <Container twoUp={twoUp}>{children}</Container>
)

export default PerkGrid
